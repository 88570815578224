import { AllianceAirlineCodes } from "./delta-earning-calculator";
import EarningRates2024 from "./earning-rates-2024.json";
import EarningRates2025 from "./earning-rates-2025.json";
import FFPInformation from "./interfaces/FFPInformation";

export const LoungeOptions = {
    "DL": {
        name: "Delta Sky Club",
        notes: "Access is allowed 3 hours before departure or on arrival.",
        access: [],
    },
    "Delta One": {
        name: "Delta One Lounge",
        access: [],
    },
    "AM": {
        name: "Aeromexico Salon Premier",
        access: [],
    },
    "AF": {
        name: "Air France Lounge",
        access: [],
    },
    "KE": {
        name: "KAL Lounge",
        access: [],
    },
    "KL": {
        name: "KLM Crown Lounge",
        access: [],
    },
    "LA": {
        name: "LATAM VIP Lounge",
        access: [],
    },
    "VS": {
        name: "Virgin Atlantic Clubhouse",
        access: [],
    },
    "WS": {
        name: "Westjet Elevation Lounge",
        access: [],
    },
    "No1": {
        name: "No1 Lounge",
        access: [],
    },
    "KQ": {
        name: "Kenya Airways Simba and Pride Lounges",
        access: [],
    },
    "AR": {
        name: "Aerolineas Argentinas Salón Cóndor",
        access: [],
    },
    "CI": {
        name: "China Airlines Lounge",
        access: [],
    },
    "VN": {
        name: "Vietnam Airlines Lotus Lounge",
        access: [],
    },
    "LY": {
        name: "King David Lounge",
        access: [],
    },
    "AMEX": {
        name: "American Express Centurion Lounge",
        access: [],
    },
    "Escape": {
        name: "Escape Lounge",
        access: [],
    },
    "Plaza": {
        name: "Plaza Premium Lounge",
        access: [],
    },
    "SkyTeam": {
        name: "SkyTeam Lounge",
        access: [],
    },
}

export const LoungeLocations = {
    "AF": [
        "BKK",
        "BOS",
        "CAY",
        "CDG",
        "DLA",
        "FDF",
        "FRA",
        "GVA",
        "IAD",
        "IAH",
        "JFK",
        "LAX",
        "LOS",
        "MUC",
        "ORY",
        "PTP",
        "RUN",
        "SFO",
        "STR",
        "YUL",
        "YYZ",
    ],
    "AM": [
        "MEX",
        "GDL",
        "TRC",
        "CUU",
        "MTY",
    ],
    "AMEX": [
        "CLT",
        "DFW",
        "DEN",
        "IAH",
        "LAS",
        "LAX",
        "MIA",
        "LGA",
        "JFK",
        "PHL",
        "PHX",
        "SFO",
        "SEA",
        "HKG",
        "LHR"
    ],
    "DL": [
        "ANC",
        "ATL",
        "AUS",
        "BNA",
        "BOS",
        "CVG",
        "DCA",
        "DEN",
        "DFW",
        "DTW",
        "EWR",
        "FLL",
        "HND",
        "HNL",
        "IND",
        "JAX",
        "JFK",
        "LAX",
        "LGA",
        "MCI",
        "MCO",
        "MEM",
        "MIA",
        "MKE",
        "MSP",
        "MSY",
        "ORD",
        "PBI",
        "PDX",
        "PHL",
        "PHX",
        "RDU",
        "SAN",
        "SEA",
        "SFO",
        "SLC",
        "TPA",
    ],
    "Delta One": [
        "JFK",
        "LAX",
        "BOS",
    ],
    "KE": [
        "JFK",
        "LAX",
        "HNL",
        "ICN",
        "GMP",
        "PUS",
        "TAE",
        "CJU",
        "NGO",
        "NRT",
        "KIX",
        "FUK"
    ],
    "KL": [
        "AMS",
        "IAH",
    ],
    "LA": [
        "SCL",
        "BOG",
        "EZE",
        "GRU",
        "MIA",
    ],
    "VS": [
        "IAD",
        "JFK",
        "JNB",
        "LHR",
        "SFO",
    ],
    "WS": [
        "YYC",
    ],
    "KQ": [
        "NBO",
    ],
    "AR": [
        "EZE",
    ],
    "CI": [
        "BKK",
        "KHH",
        "NRT",
        "SFO",
        "TPE",
        "TSA",
    ],
    "VN": [
        "CXR",
        "DLI",
        "HAN",
        "HPH",
        "HUI",
        "SGN",
        "VII",
    ],
    "LY": [
        "TLV",
    ],
    "SkyTeam": [
        "DXB",
        "IST",
        "SCL",
        "SYD",
        "YVR",
    ],
    "Escape": [
        "BDL",
        "CVG",
        "GSP",
        "MSP",
        "OAK",
        "PBI",
        "PHX",
        "RNO",
        "PVD",
        "SMF",
        "FLL",
        "CMH",
        "SYR",
    ],
    "Plaza": [
        "ADD",
        "ADL",
        "BAQ",
        "BGA",
        "BNE",
        "BOG",
        "BUD",
        "CAN",
        "CEB",
        "CGK",
        "CLO",
        "CRK",
        "CTG",
        "CUN",
        "DFW",
        "DMM",
        "EDI",
        "EWR",
        "FCO",
        "FRA",
        "GIG",
        "GRU",
        "HEL",
        "HKG",
        "JED",
        "JNB",
        "KUL",
        "LGK",
        "LGW",
        "LHR",
        "MCO",
        "MDE",
        "MEL",
        "MFM",
        "NGO",
        "PEN",
        "PKX",
        "PNH",
        "REP",
        "RUH",
        "SAL",
        "SAW",
        "SIN",
        "SYD",
        "TPE",
        "YEG",
        "YUL",
        "YVR",
        "YWG",
        "YYZ",
    ],
};

const DeltaNonAlliancePartners = [
    "WS",
    "LA",
];

const DeltaOneLoungeClosePartners = [
    "AF",
    "KE",
    "KL",
    "LA",
    "VS",
];

export const CaribbeanAirports = [
    "AUA",
    "AVI",
    "AXA",
    "BCA",
    "BDA",
    "BGI",
    "BON",
    "BQU",
    "BWW",
    "BYM",
    "CCC",
    "CFG",
    "CIW",
    "CMW",
    "CYO",
    "EUX",
    "GAO",
    "GER",
    "HAV",
    "HOG",
    "LCL",
    "MNI",
    "MOA",
    "MQS",
    "MZO",
    "NBW",
    "POS",
    "QPD",
    "QSN",
    "SAB",
    "SCU",
    "SFG",
    "SNJ",
    "SNU",
    "SVD",
    "SXM",
    "SZJ",
    "TAB",
    "TND",
    "UMA",
    "UNI",
    "UPB",
    "USS",
    "VRA",
    "VTU",
];

export function calculateLounge(
    ffpInformation: FFPInformation,
    line: { marketing?: string, operating?: string, fareClass?: string, upgradedFareClass?: string, from: string, fromAirport: any, to: string, toAirport: any }[],
    flight: { marketing?: string, operating?: string, fareClass?: string, upgradedFareClass?: string, from: string, to: string },
) {
    const EarningRates = ffpInformation.travelYear === '2023' ? EarningRates2024 : EarningRates2025;

    const lounges = JSON.parse(JSON.stringify(LoungeOptions));
    const incomingFlightIndex = line.findIndex(x => x === flight);
    const incomingFlight = incomingFlightIndex > 0 ? line[incomingFlightIndex - 1] : null;

    // TODO: Add Caribbean exceptions.
    const soleUSAndCarribbeanItinerary = line
        .filter(f =>
            (f.fromAirport?.country !== "United States" && !CaribbeanAirports.includes(f.from) && !["ROR", "SPN"].includes(f.from))
            || (f.toAirport?.country !== "United States" && !CaribbeanAirports.includes(f.to) && !["ROR", "SPN"].includes(f.to))
        ).length === 0;
    const internationalItinerary = line.filter(f => f.fromAirport?.country !== f.toAirport?.country).length > 0 && !soleUSAndCarribbeanItinerary;
    const latamInternationalItinerary = line
        .filter(f => f.fromAirport?.country !== f.toAirport?.country)
        .filter(f => !f.marketing || ["DL", "LA"].includes(f.operating || f.marketing))
        .length > 0 && !soleUSAndCarribbeanItinerary;
    const westjetInternationalItinerary = line
        .filter(f => f.fromAirport?.country !== f.toAirport?.country)
        .filter(f => !f.marketing || ["DL", "WS"].includes(f.operating || f.marketing))
        .length > 0 && !soleUSAndCarribbeanItinerary;
    const skyteamInternationalItinerary = line
        .filter(f => f.fromAirport?.country !== f.toAirport?.country)
        .filter(f => !f.marketing || AllianceAirlineCodes["SkyTeam"].includes(f.operating || f.marketing))
        .length > 0 && !soleUSAndCarribbeanItinerary;
    const basicEconomy = ((flight.marketing === undefined || flight.marketing === "DL") && flight.fareClass === "E")
        || (flight.marketing === "VS" && flight.fareClass === "T")
        || (flight.marketing === "WS" && flight.fareClass === "E");

    // We treat the whole itinerary as premium economy / business / first if at least one flight is.
    // FIXME: This is not compatible with KLM's split fare classes (WSA are premium economy transastlantic and economy in Europe).
    // FIXME: This is not how lounge access works for AFKL.
    const premiumEconomy = line.filter(f => {
        if (f.marketing === undefined || f.marketing === "DL")
            return ["P", "A", "G", "R"].includes(f.upgradedFareClass?.[0] || f.fareClass || "");
        return (EarningRates.ffp.DL as any)[f.marketing]?.charts?.[""]
            ?.filter((l: any) => l.class.includes(f.upgradedFareClass?.[0] || f.fareClass || "") && l.cabin.startsWith("Premium Economy")).length > 0;
    }).length > 0;
    const business = line.filter(f => {
        if (f.marketing === undefined || f.marketing === "DL")
            return ["J", "C", "D", "I", "Z", "O"].includes(f.upgradedFareClass?.[0] || f.fareClass || "");
        return (EarningRates.ffp.DL as any)[f.marketing]?.charts?.[""]
            ?.filter((l: any) => l.class.includes(f.upgradedFareClass?.[0] || f.fareClass || "") && l.cabin.startsWith("Business")).length > 0;
    }).length > 0;
    const first = line.filter(f => {
        if (f.marketing === undefined || f.marketing === "DL")
            return (f.upgradedFareClass?.[0] || f.fareClass) === "F";
        return (EarningRates.ffp.DL as any)[f.marketing]?.charts?.[""]
            ?.filter((l: any) => l.class.includes(f.upgradedFareClass?.[0] || f.fareClass || "") && (l.cabin.startsWith("First"))).length > 0;
    }).length > 0;

    const isSkyteamElitePlus = ffpInformation.program === "AFKL" && ["gold", "plat", "ulti", "club", "skip", "hipp"].includes(ffpInformation.eliteStatus ?? "");

    if (LoungeLocations["AMEX"].includes(flight.from)) {
        if (ffpInformation.creditCards.includes("amex-plat"))
            lounges["AMEX"].access.push({
                name: "American Express Platinum",
                guests: {
                    number: "*",
                    description: "No guests. Cardmembers that spent $75,000 on their card in the previous year may bring 2 guests.",
                },
            });
        if (ffpInformation.creditCards.includes("amex-plat-business"))
            lounges["AMEX"].access.push({
                name: "American Express Business Platinum",
                guests: {
                    number: "*",
                    description: "No guests. Cardmembers that spent $75,000 on their card in the previous year may bring 2 guests.",
                },
            });
        if (ffpInformation.creditCards.includes("amex-cent"))
            lounges["AMEX"].access.push({
                name: "American Express Centurion",
                guests: {
                    number: 2,
                    description: "2 guests",
                },
            });
        if (!flight.marketing || flight.operating === "DL" || flight.marketing === "DL") {
            if (ffpInformation.creditCards.includes("dl-reserve"))
                lounges["AMEX"].access.push({
                    name: "Delta SkyMiles Reserve American Express Card",
                    guests: {
                        number: 0,
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
            if (ffpInformation.creditCards.includes("dl-reserve-business"))
                lounges["AMEX"].access.push({
                    name: "Delta SkyMiles Reserve Business American Express Card",
                    guests: {
                        number: 0,
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
        }
    }
    if (LoungeLocations["Escape"].includes(flight.from)) {
        if (ffpInformation.creditCards.includes("amex-plat"))
            lounges["Escape"].access.push({
                name: "American Express Platinum",
                guests: {
                    number: 2,
                    description: "2 guests",
                },
            });
        if (ffpInformation.creditCards.includes("amex-plat-business"))
            lounges["Escape"].access.push({
                name: "American Express Business Platinum",
                guests: {
                    number: 2,
                    description: "2 guests",
                },
            });
        if (ffpInformation.creditCards.includes("amex-cent"))
            lounges["Escape"].access.push({
                name: "American Express Centurion",
                guests: {
                    number: 2,
                    description: "2 guests",
                },
            });
        if (!flight.marketing || flight.operating === "DL" || flight.marketing === "DL") {
            if (ffpInformation.creditCards.includes("dl-reserve"))
                lounges["Escape"].access.push({
                    name: "Delta SkyMiles Reserve American Express Card",
                    guests: {
                        number: 0,
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
            if (ffpInformation.creditCards.includes("dl-reserve-business"))
                lounges["Escape"].access.push({
                    name: "Delta SkyMiles Reserve Business American Express Card",
                    guests: {
                        number: 0,
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
        }
    }
    if (LoungeLocations["Plaza"].includes(flight.from)) {
        if (ffpInformation.creditCards.includes("amex-plat"))
            lounges["Plaza"].access.push({
                name: "American Express Platinum",
                guests: {
                    number: 2,
                    description: "2 guests",
                },
            });
        if (ffpInformation.creditCards.includes("amex-plat-business"))
            lounges["Plaza"].access.push({
                name: "American Express Business Platinum",
                guests: {
                    number: 2,
                    description: "2 guests",
                },
            });
        if (ffpInformation.creditCards.includes("amex-cent"))
            lounges["Plaza"].access.push({
                name: "American Express Centurion",
                guests: {
                    number: 2,
                    description: "2 guests",
                },
            });
    }

    if (LoungeLocations["DL"].includes(flight.from)) {
        // Amex Platinum and Centurion
        if ((ffpInformation.travelYear === '2023' || !basicEconomy) && [flight, incomingFlight].filter(x => x && (!x.marketing || (x.operating || x.marketing) === "DL" || (x.marketing === "DL" && x.operating === "WS"))).length > 0) {
            if (ffpInformation.creditCards.includes("amex-plat"))
                lounges["DL"].access.push({
                    name: "American Express Platinum",
                    guests: {
                        number: "0",
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
            if (ffpInformation.creditCards.includes("amex-plat-business"))
                lounges["DL"].access.push({
                    name: "American Express Business Platinum",
                    guests: {
                        number: "0",
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
            if (ffpInformation.creditCards.includes("amex-cent"))
                lounges["DL"].access.push({
                    name: "American Express Centurion",
                    guests: {
                        number: 0,
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
        }

        // Delta Sky Club Member
        if (!basicEconomy && [flight, incomingFlight].filter(x => x && (x.marketing === undefined || AllianceAirlineCodes["SkyTeam"].includes(x.operating || x.marketing) || DeltaNonAlliancePartners.includes(x.operating || x.marketing || ""))).length > 0) {
            if (ffpInformation.endorsements.includes("sky-club-individual"))
                lounges["DL"].access.push({
                    name: "Delta Sky Club Individual Membership",
                    guests: {
                        number: 0,
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
            if (ffpInformation.endorsements.includes("sky-club-executive"))
                lounges["DL"].access.push({
                    name: "Delta Sky Club Executive Membership",
                    guests: {
                        number: 2,
                        description: "$50 each for up to 2 additional guests or immediate family.",
                    },
                });
            if (ffpInformation.eliteStatus === "360")
                lounges["DL"].access.push({
                    name: "Delta 360",
                    guests: {
                        number: 2,
                        description: "$50 each for up to 2 additional guests or immediate family.",
                    },
                });
        }

        // Delta Reserve Card
        if ((ffpInformation.travelYear === '2023' || !basicEconomy) && [flight, incomingFlight].filter(x => x && (x?.operating === "DL" || x?.marketing === "DL" || !x?.marketing)).length > 0) {
            if (ffpInformation.creditCards.includes("dl-reserve"))
                lounges["DL"].access.push({
                    name: "Delta SkyMiles Reserve American Express Card",
                    guests: {
                        number: 0,
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
            if (ffpInformation.creditCards.includes("dl-reserve-business"))
                lounges["DL"].access.push({
                    name: "Delta SkyMiles Reserve Business American Express Card",
                    guests: {
                        number: 0,
                        description: "$50 each for up to 2 paid guests or immediate family.",
                    },
                });
        }

        // Gold Medallion, Platinum Medallion, Diamond Medallion
        if (ffpInformation.program === "DL" && internationalItinerary && (premiumEconomy || business || first) && (flight.marketing === undefined || AllianceAirlineCodes["SkyTeam"].includes(flight.operating || flight.marketing) || DeltaNonAlliancePartners.includes(flight.operating || flight.marketing))) {
            if (ffpInformation.eliteStatus === "GM")
                lounges["DL"].access.push({
                    name: "Delta Gold Medallion",
                    guests: {
                        number: 1,
                        description: "Guests must also be travelling in Premium Economy, Business, or First on a Delta or Delta Partner flight.",
                    },
                });
            if (ffpInformation.eliteStatus === "PM")
                lounges["DL"].access.push({
                    name: "Delta Platinum Medallion",
                    guests: {
                        number: 1,
                        description: "Guests must also be travelling in Premium Economy, Business, or First on a Delta or Delta Partner flight.",
                    },
                });
            if (ffpInformation.eliteStatus === "DM")
                lounges["DL"].access.push({
                    name: "Delta Diamond Medallion",
                    guests: {
                        number: 1,
                        description: "Guests must also be travelling in Premium Economy, Business, or First on a Delta or Delta Partner flight.",
                    },
                });
        }

        // SkyTeam Elite+
        if (ffpInformation.program !== "DL" && isSkyteamElitePlus && internationalItinerary && (flight.marketing === undefined || AllianceAirlineCodes["SkyTeam"].includes(flight.operating || flight.marketing))) {
            lounges["DL"].access.push({
                name: "SkyTeam Elite Plus",
                guests: {
                    number: 1,
                    description: "Guests must also be travelling in Premium Economy, Business, or First on a Delta or Delta Partner flight.",
                },
            });
        }

        // SkyTeam business or first, including international Delta One
        if (skyteamInternationalItinerary && (business || first) && (flight.marketing === undefined || AllianceAirlineCodes["SkyTeam"].includes(flight.operating || flight.marketing) || DeltaNonAlliancePartners.includes(flight.operating || flight.marketing))) {
            if (flight.operating !== "DL" && (flight.marketing !== undefined && flight.marketing !== "DL")) {
                if (business)
                    lounges["DL"].access.push({
                        name: "SkyTeam Business Class",
                        guests: {
                            number: 0
                        },
                    });
                if (first)
                    lounges["DL"].access.push({
                        name: "SkyTeam First Class",
                        guests: {
                            number: 0
                        },
                    });
            }
            else {
                lounges["DL"].access.push({
                    name: "Delta One",
                    guests: {
                        number: 0,
                    },
                });
                if (LoungeLocations["Delta One"].includes(flight.from)) {
                    lounges["Delta One"].access.push({
                        name: "Delta One",
                        guests: {
                            number: 0,
                        },
                    });
                }
            }
        }

        // Delta One Domestic
        if (business && (!flight.marketing || AllianceAirlineCodes["SkyTeam"].includes(flight.operating || flight.marketing))) {
            if (["JFKLAX", "JFKSFO", "JFKSAN", "JFKSEA", "JFKLAS", "BOSLAX", "DCALAX", "HNLJFK", "ATLHNL", "ATLOGG", "DTWHNL", "HNLMSP"].includes([flight.from, flight.to].sort().join(''))) {
                lounges["DL"].access.push({
                    name: "Delta One",
                    guests: {
                        number: 0,
                    },
                });
                if (LoungeLocations["Delta One"].includes(flight.from) && (flight.operating || flight.marketing || 'DL') === 'DL') {
                    lounges["Delta One"].access.push({
                        name: "Delta One",
                        guests: {
                            number: 0,
                        },
                    });
                }
            }
        }

        // Special Delta One lounge consideration for close partners.
        if (LoungeLocations["Delta One"].includes(flight.from) && skyteamInternationalItinerary && (business || first) && (flight.marketing === undefined || DeltaOneLoungeClosePartners.includes(flight.operating || flight.marketing))) {
            if (flight.from !== 'BOS' || ['DL', 'VS', 'KE', 'LA'].includes(flight.operating || flight.marketing || 'DL')) {
                lounges["Delta One"].access.push({
                    name: "Delta One Lounge - Close Partner",
                    guests: {
                        number: 0,
                    },
                });
            }
        }
    }

    for (const carrier of ["AM", "AF", "KE", "KL", "VS", "KQ", "AR", "CI", "VN", "SkyTeam"]) {
        if (LoungeLocations[carrier as "AM" | "AF" | "KE" | "KL" | "VS" | "KQ" | "AR" | "CI" | "VN" | "SkyTeam"]?.includes(flight.from) && !(carrier === "VS" && flight.from === "LHR")) {
            // SkyTeam business or first, including international Delta One
            if (!soleUSAndCarribbeanItinerary && (business || first) && (!flight.marketing || AllianceAirlineCodes["SkyTeam"].includes(flight.operating || flight.marketing))) {
                if (business)
                    lounges[carrier].access.push({
                        name: "SkyTeam Business Class",
                        guests: {
                            number: 0
                        },
                    });
                if (first)
                    lounges[carrier].access.push({
                        name: "SkyTeam First Class",
                        guests: {
                            number: 0
                        },
                    });
            }

            // Gold Medallion, Platinum Medallion, Diamond Medallion
            if (ffpInformation.program === "DL" && skyteamInternationalItinerary && (!flight.marketing || AllianceAirlineCodes["SkyTeam"].includes(flight.operating || flight.marketing))) {
                if (ffpInformation.eliteStatus === "GM")
                    lounges[carrier].access.push({
                        name: "Delta Gold Medallion",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.eliteStatus === "PM")
                    lounges[carrier].access.push({
                        name: "Delta Platinum Medallion",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.eliteStatus === "DM")
                    lounges[carrier].access.push({
                        name: "Delta Diamond Medallion",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.eliteStatus === "360") {
                    lounges[carrier].access.push({
                        name: "Delta 360",
                        guests: {
                            number: 1,
                        },
                    });
                }
            }

            if (ffpInformation.program !== "DL" && isSkyteamElitePlus && skyteamInternationalItinerary && (!flight.marketing || AllianceAirlineCodes["SkyTeam"].includes(flight.operating || flight.marketing))) {
                lounges[carrier].access.push({
                    name: "SkyTeam Elite Plus",
                    guests: {
                        number: 1,
                    },
                });
            }
            if (LoungeLocations["Delta One"].includes(flight.from) && (business || first) && (flight.operating || flight.marketing || "DL") === "DL") {
                lounges["Delta One"].access.push({
                    name: "Delta One Lounge - 360 in Delta First",
                    guests: {
                        number: 0,
                    },
                });            
            }
        }
    }

    // VS exception for LHR
    if (flight.from === "LHR") {
        if (business && (["DL", "AM"].includes(flight.operating || "") || (["DL", "AM"].includes(flight.marketing || "DL") && !flight.operating)))
            lounges["VS"].access.push({
                name: "SkyTeam Business Class",
                guests: {
                    number: 0,
                },
            });
        if (business && (flight.operating === "VS" || (flight.marketing === "VS" && !flight.operating)))
            lounges["VS"].access.push({
                name: "Virgin Atlantic Upper Class",
                guests: {
                    number: 1,
                },
            });

        // Gold Medallion, Platinum Medallion, Diamond Medallion
        if (skyteamInternationalItinerary && (["AM", "DL", "VS"].includes(flight.operating || flight.marketing || "DL"))) {
            const carrier = flight.operating || flight.marketing || "DL";

            const isPremiumEconomyOrBusiness =
                (carrier === "DL" && ["J", "D", "I", "C", "Z", "O", "P", "A", "G", "R"].includes(flight.upgradedFareClass?.[0] || flight.fareClass || ""))
                || (["VS", "AM"].includes(carrier) && (EarningRates.ffp.DL as any)[carrier]?.charts?.[""]?.filter((l: any) => l.class.includes(flight.upgradedFareClass?.[0] || flight.fareClass || "") && (l.cabin.startsWith("Premium Economy") || l.cabin.startsWith("Business"))).length > 0);
            if (isPremiumEconomyOrBusiness) {
                if (ffpInformation.program === "DL" && ffpInformation.eliteStatus === "PM")
                    lounges["VS"].access.push({
                        name: "Delta Platinum Medallion",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "DL" && ffpInformation.eliteStatus === "DM")
                    lounges["VS"].access.push({
                        name: "Delta Diamond Medallion",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "DL" && ffpInformation.eliteStatus === "360")
                    lounges["VS"].access.push({
                        name: "Delta 360",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "plat")
                    lounges["VS"].access.push({
                        name: "Flying Blue Platinum",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "ulti")
                    lounges["VS"].access.push({
                        name: "Flying Blue Platinum Ultimate",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "skip")
                    lounges["VS"].access.push({
                        name: "Flying Blue Skipper of the Flying Dutchman",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "hipp")
                    lounges["VS"].access.push({
                        name: "Flying Blue Hippocampe",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "club")
                    lounges["VS"].access.push({
                        name: "Flying Blue Club 2000",
                        guests: {
                            number: 1,
                        },
                    });
            }
            else {
                if (ffpInformation.program === "DL" && ffpInformation.eliteStatus === "PM")
                    lounges["No1"].access.push({
                        name: "Delta Platinum Medallion",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "DL" && ffpInformation.eliteStatus === "DM")
                    lounges["No1"].access.push({
                        name: "Delta Diamond Medallion",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "DL" && ffpInformation.eliteStatus === "360")
                    lounges["No1"].access.push({
                        name: "Delta 360",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "plat")
                    lounges["No1"].access.push({
                        name: "Flying Blue Platinum",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "ulti")
                    lounges["No1"].access.push({
                        name: "Flying Blue Platinum Ultimate",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "skip")
                    lounges["No1"].access.push({
                        name: "Flying Blue Skipper of the Flying Dutchman",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "hipp")
                    lounges["No1"].access.push({
                        name: "Flying Blue Hippocampe",
                        guests: {
                            number: 1,
                        },
                    });
                if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "club")
                    lounges["No1"].access.push({
                        name: "Flying Blue Club 2000",
                        guests: {
                            number: 1,
                        },
                    });
            }

            if (ffpInformation.program === "DL" && ffpInformation.eliteStatus === "GM")
                lounges["No1"].access.push({
                    name: "Delta Gold Medallion",
                    guests: {
                        number: 1,
                    },
                });

            if (ffpInformation.program === "AFKL" && ffpInformation.eliteStatus === "gold")
                lounges["No1"].access.push({
                    name: "Flying Blue Gold",
                    guests: {
                        number: 1,
                    },
                });
        }
    }

    // LATAM
    if (LoungeLocations["LA"]?.includes(flight.from)) {
        // SkyTeam business or first, including international Delta One
        if (!soleUSAndCarribbeanItinerary && business && (!flight.marketing || ["LA", "DL"].includes(flight.operating || flight.marketing))) {
            if (business)
                lounges["LA"].access.push({
                    name: "LATAM or Delta Business Class",
                    guests: {
                        number: 0
                    },
                });
        }

        // Gold Medallion, Platinum Medallion, Diamond Medallion
        if (latamInternationalItinerary && (!flight.marketing || ["LA", "DL"].includes(flight.operating || flight.marketing))) {
            if (ffpInformation.eliteStatus === "GM")
                lounges["LA"].access.push({
                    name: "Delta Gold Medallion",
                    guests: {
                        number: 1,
                    },
                });
            if (ffpInformation.eliteStatus === "PM")
                lounges["LA"].access.push({
                    name: "Delta Platinum Medallion",
                    guests: {
                        number: 1,
                    },
                });
            if (ffpInformation.eliteStatus === "DM")
                lounges["LA"].access.push({
                    name: "Delta Diamond Medallion",
                    guests: {
                        number: 1,
                    },
                });
            if (ffpInformation.eliteStatus === "360")
                lounges["LA"].access.push({
                    name: "Delta 360",
                    guests: {
                        number: 1,
                    },
                });
        }
    }

    // Westjet
    if (LoungeLocations["WS"]?.includes(flight.from)) {
        // SkyTeam business or first, including international Delta One
        if (business && (!flight.marketing || ["WS", "DL"].includes(flight.operating || flight.marketing))) {
            if (business)
                lounges["WS"].access.push({
                    name: "Westjet or Delta Business Class",
                    guests: {
                        number: 0
                    },
                });
        }

        // Gold Medallion, Platinum Medallion, Diamond Medallion
        if (westjetInternationalItinerary && (!flight.marketing || ["WS", "DL"].includes(flight.operating || flight.marketing))) {
            if (ffpInformation.eliteStatus === "GM")
                lounges["WS"].access.push({
                    name: "Delta Gold Medallion",
                    guests: {
                        number: 1,
                    },
                });
            if (ffpInformation.eliteStatus === "PM")
                lounges["WS"].access.push({
                    name: "Delta Platinum Medallion",
                    guests: {
                        number: 1,
                    },
                });
            if (ffpInformation.eliteStatus === "DM")
                lounges["WS"].access.push({
                    name: "Delta Diamond Medallion",
                    guests: {
                        number: 1,
                    },
                });
            if (ffpInformation.eliteStatus === "360")
                lounges["WS"].access.push({
                    name: "Delta 360",
                    guests: {
                        number: 1,
                    },
                });
        }
    }

    // Westjet
    if (LoungeLocations["LY"]?.includes(flight.from)) {
        // SkyTeam business or first, including international Delta One
        if (business && (!flight.marketing || ["LY", "DL"].includes(flight.operating || flight.marketing))) {
            if (business)
                lounges["LY"].access.push({
                    name: "EL AL or Delta Business Class",
                    guests: {
                        number: 0
                    },
                });
        }

        // Gold Medallion, Platinum Medallion, Diamond Medallion
        if (westjetInternationalItinerary && (!flight.marketing || ["LY", "DL"].includes(flight.operating || flight.marketing))) {
            if (ffpInformation.eliteStatus === "GM")
                lounges["LY"].access.push({
                    name: "Delta Gold Medallion",
                    guests: {
                        number: 1,
                    },
                });
            if (ffpInformation.eliteStatus === "PM")
                lounges["LY"].access.push({
                    name: "Delta Platinum Medallion",
                    guests: {
                        number: 1,
                    },
                });
            if (ffpInformation.eliteStatus === "DM")
                lounges["LY"].access.push({
                    name: "Delta Diamond Medallion",
                    guests: {
                        number: 1,
                    },
                });
            if (ffpInformation.eliteStatus === "360")
                lounges["LY"].access.push({
                    name: "Delta 360",
                    guests: {
                        number: 1,
                    },
                });
        }
    }

    return { lounges: Object.fromEntries(Object.entries(lounges).filter(([_, lounge]: any) => lounge?.access?.length > 0)) };
}
